<template>
    <div class="boxListCon">
    	<el-table v-if="showNodeCur" :data="tableData" style="width: 100%">
    		<el-table-column prop="date" width="120">
    			<div class="cart">
    				<el-icon :size="20"><ShoppingCartFull /></el-icon>
    				Sale
    			</div>
    		</el-table-column>
    		<el-table-column prop="name" label="Item">
    			<div class="user">
    				<span>
    					<img src="../../../../assets/img/use0.png">
    				</span>
    				<span class="text">
    					<div>Abuse_Voice365</div>
    					<div>01/13Abuse_Voice</div>
    				</span>
    			</div>
    		</el-table-column>
    		<el-table-column prop="address" label="Price" width="150">
    			<div style="font-weight:bold;font-size:16px;">0.168</div>
    			<div>$4.65</div>
    		</el-table-column>
    		<el-table-column prop="" label="Quantity" width="150" />
    		<el-table-column prop="address" label="From" width="200" />
    		<el-table-column prop="address" label="To" width="200" />
    		<el-table-column prop="time" label="Time" width="250" />
    	  </el-table>
    </div>
</template>
<script>
	export default {
		props:['showNode'],
		data() {
			return{
				showNodeCur:true,
				tableData:[
					{
						date: '2016-05-03',
						name: 'Tom',
						address: '1',
						time: '10s',
					},
					{
						date: '2016-05-03',
						name: 'Tom',
						address: '1',
						time: '10s',
					},
					{
						date: '2016-05-03',
						name: 'Tom',
						address: '1',
						time: '10s',
					},
					{
						date: '2016-05-03',
						name: 'Tom',
						address: '1',
						time: '10s',
					},
					{
						date: '2016-05-03',
						name: 'Tom',
						address: '1',
						time: '10s',
					}
				]
			}
		},
		created(){
			
		},
		methods:{
			
		},
		watch: {
			showNode(newValue, oldValue) {
				if(newValue){
					this.showNodeCur = false;
					setTimeout(()=>{
						this.showNodeCur = true;
					},50)
				}
			}
		},
	};
</script>
<style scoped>
	.boxListCon{
		padding:0px 20px;
	}
	.boxListCon .cart{
		font-size:16px;
		font-weight:bold;
	}
	.boxListCon .cart .el-icon{
		vertical-align: sub;
	}
	.user span{
		display: inline-block;
	}
	.user span:first-child{
		vertical-align: bottom;
	}
	.user .text div:first-child{
		color:#909399;
	}
	.user .text div:last-child{
		color:#000;
		font-weight:bold;
	}
	.user img{
		width:48px;
		height:48px;
		border-radius: 10px;
		vertical-align: text-top;
		margin-right:8px;
	}
</style>
